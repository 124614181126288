<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="bar">
        <div class="title">当前积分：{{ integral }}</div>  
        <div class="sz">
          <div class="text">收：{{ inamount }}</div>
          <div class="text">支：{{ payamount }}</div>
        </div>   
      </div>
      <div class="content">
        <div class="items">
          <div class="empty" v-if="items.length === 0">
            <div>
              <img src="@/assets/empty.png" />
            </div>
            <div class="text">当前无信息</div>
          </div>
          <div class="item">
            <div class="box qq">
              <div class="name">
                <div class="headline h1">{{ startdate }}&ensp;-&ensp;{{ enddate }}</div>
                <div v-if="gdflag">
                  <div class="headline h2 d3 xz cur" @click="gd()">更多</div>
                </div>
                <div v-if="gdflag === false">
                  <div class="headline h2 d3 cur" @click="gd()">更多</div>
                </div>              
                <div v-if="byflag">
                  <div class="headline h2 d3 xz cur" @click="by()">本月</div>
                </div>
                <div v-if="byflag === false">
                  <div class="headline h2 d3 cur" @click="by()">本月</div>
                </div>                                
                <div v-if="bzflag">
                  <div class="headline h2 d3 xz cur" @click="bz()">本周</div>
                </div>
                <div v-if="bzflag === false">
                  <div class="headline h2 d3 cur" @click="bz()">本周</div> 
                </div> 
                <div v-if="jrflag">
                  <div class="headline h2 d3 xz cur" @click="jr()">今日</div>
                </div>
                <div v-if="jrflag === false">
                  <div class="headline h2 d3 cur" @click="jr()">今日</div> 
                </div>                                          
              </div>          
            </div>           
          </div>
          <div v-show="gdflag">
          <div class="item">
            <div class="box qq">
              <div class="name">  
                <div v-if="sgyflag">
                  <span class="headline h1 xz cur" @click="sgy()">三个月</span>
                </div>
                <div v-if="sgyflag === false">
                  <span class="headline h1 cur" @click="sgy()">三个月</span>
                </div>             
                <div v-if="lgyflag">
                  <span class="headline h1 xz cur" @click="lgy()">六个月</span>
                </div>
                <div v-if="lgyflag === false">
                  <span class="headline h1 cur" @click="lgy()">六个月</span>
                </div>
                <div v-if="zdyflag">
                  <span class="headline h1 xz cur" @click="zdy()">自定义</span>
                </div>
                <div v-if="zdyflag === false">
                  <span class="headline h1 cur" @click="zdy()">自定义</span>
                </div>               
                <!--<div class="headline h2" v-show="zdyflag">
                  <input type="text" class="datespace" v-model="dateSpace" ref="calendarTigger" placeholder="请选择时间区间" readonly>
                </div>-->
              </div>          
            </div>           
          </div>
          </div>
          <div v-show="zdyflag">
          <div class="item">
            <div class="box zz">
              <div class="name"> 
                <div class="headline h1 d5">起始日期：</div>               
                <div class="headline h1 d5">                  
                  <input type="date" class="datespace" v-model="startdate"/>
                </div>                
              </div>  
              <div class="name"> 
                <div class="headline h1 d5">结束日期：</div>
                <div class="headline h1 d5">                  
                  <input type="date" class="datespace" v-model="enddate"/>
                </div>                
              </div>        
            </div>  
            <div class="box yy">
              <div class="name"> 
                <div class="headline h2 d6">                  
                  <div class="rb2">
                    <img src="@/assets/cx.png" class="icon" />
                    <div class="text">查询</div>
                  </div>
                </div>                
              </div>          
            </div>         
          </div>
          </div>
          <div class="item" v-for="item in items" :key="item.id">
            <div class="box zz">
              <div class="name">{{ item.operation }}</div>
              <div class="unames">
                <span class="uu">{{ item.opdate }}</span>
                <span class="uu">操作人：{{ item.opname }}</span>
              </div>
            </div>
            <div class="box yy">
              <div class="unames dd">{{ item.amount }}</div>             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, map } from 'lodash-es';
import { Dialog, Toast } from 'vant';
import qs from 'qs';
import moment from "moment";
export default {
  created() {
    //this.query();
    this.startdate = this.getdate();
    this.enddate = this.getdate();
    this.nowdate = this.getdate();
  },
  data() {
    return {
      integral: 168,
      payamount: 68,
      inamount: 690,
      jrflag: true,
      byflag: false,   
      bzflag: false, 
      gdflag: false, 
      sgyflag: false,
      lgyflag: false,
      zdyflag: false,
      startdate: '',
      enddate: '',
      nowdate: '',
      items: [
        {operation: '自助登记', opdate: '2020-01-06 15:20:53', opname: '张某某', amount: '+10'},
        {operation: '协助登记', opdate: '2020-02-16 18:35:16', opname: '李某某', amount: '-10'},
        {operation: '购买积分', opdate: '2020-03-28 09:56:21', opname: '刘某某', amount: '+30'},
      ]
    };
  },
  methods: {
    jr() {
      this.jrflag = true;
      this.byflag = false;
      this.bzflag = false;
      this.gdflag = false;
      this.sgyflag = false;
      this.lgyflag = false;
      this.zdyflag = false;

      this.startdate = this.getdate();
      this.enddate = this.getdate();
    },
    by() {
      this.jrflag = false;
      this.byflag = true;
      this.bzflag = false;
      this.gdflag = false;
      this.sgyflag = false;
      this.lgyflag = false;
      this.zdyflag = false;

      this.startdate = moment(moment().month(moment().month()).startOf('month').valueOf()).format('YYYY-MM-DD');
      this.enddate = moment(moment().month(moment().month()).endOf('month').valueOf()).format('YYYY-MM-DD');
    },
    bz() {
      this.jrflag = false;
      this.byflag = false;
      this.bzflag = true;
      this.gdflag = false;
      this.sgyflag = false;
      this.lgyflag = false;
      this.zdyflag = false;

      this.startdate = moment(moment().week(moment().week()).startOf('week').add(1, 'days').valueOf()).format('YYYY-MM-DD')
      this.enddate = moment(moment().week(moment().week()).endOf('week').add(1, 'days').valueOf()).format('YYYY-MM-DD');
    },
    gd() {
      this.jrflag = false;
      this.byflag = false;
      this.bzflag = false;
      this.gdflag = true;
      this.sgyflag = false;
      this.lgyflag = false;
      this.zdyflag = false;
    },
    sgy() {
      this.jrflag = false;
      this.byflag = false;
      this.bzflag = false;
      this.gdflag = true;
      this.sgyflag = true;
      this.lgyflag = false;
      this.zdyflag = false;

      this.startdate = this.nowdate;
      this.enddate = this.computeYmd(this.nowdate, 3);
    },
    lgy() {
      this.jrflag = false;
      this.byflag = false;
      this.bzflag = false;
      this.gdflag = true;
      this.sgyflag = false;
      this.lgyflag = true;
      this.zdyflag = false;

      this.startdate = this.nowdate;
      this.enddate = this.computeYmd(this.nowdate, 6);
    },
    zdy() {
      this.jrflag = false;
      this.byflag = false;
      this.bzflag = false;
      this.gdflag = true;
      this.sgyflag = false;
      this.lgyflag = false;
      this.zdyflag = true;
    },
    getdate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();

      if (month >= 1 && month <= 9) {
          month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
          strDate = "0" + strDate;
      }
      var currentdate = year + "-" + month + "-" + strDate;
      return currentdate;
    },   
    computeYmd(val, n) {// 获取给定日期的 n个月后的日期  给定日期格式如：val = 2019-02-26; n = 多少个月
      let str = val.split('-');
      let d = new Date(str[0], str[1], str[2]);
      // 因为getMonth()获取的月份的值只能在0~11之间所以我们在进行setMonth()之前先给其减一
      d.setMonth((d.getMonth()-1) + n);
      let yy1 = d.getFullYear();
      let mm1 = d.getMonth()+1;
      let dd1 = d.getDate()-1;
      if(dd1 == '00'){
        mm1 = parseInt(mm1)-1;
        let new_date = new Date(yy1,mm1,1);
        dd1 = (new Date(new_date.getTime()-1000*60*60*24)).getDate()
      }
      if (mm1 < 10 ) {
        mm1 = '0' + mm1;
      }
      if (dd1 < 10) {
        dd1 = '0' + dd1;
      }
      return yy1 + '-' + mm1 + '-' + dd1;
    },
    selectFn(e) {
      Toast(e.target.value);
      // console.log(e)
      // console.log(e.target.selectedIndex) // 选择项的index索引
      // console.log(e.target.value) // 选择项的value
    },
    async query() {
      /*let { data } = await this.axios.get('/ctid/tlService/selectdoor');
      this.items = data.data;*/
    },
    onItemClick(item) {
      //this.$router.push('/position/detail/' + item.client_id);
    },
    onDelete(item) {
      /*Dialog.confirm({
        message: '是否删除岗位[' + item.client_name + ']?'
      }).then(async () => {
        let { data } = await this.axios.post('/ctid/tlService/deletedoor', qs.stringify({ client_id: item.client_id }));
        if (data.flag === '0') {
          Toast('删除成功!');
          this.query();
        } else {
          Dialog({ message: data.message });
        }
      });*/
    },
    onEdit(item) {
      /*Dialog.confirm({
        message: '确认进行审批方式设置吗?'
      }).then(async () =>{       
        this.$router.push('/position/bind_post');
      });*/
    }
    
  }
};
</script>

<style lang="less" scoped>

.bar {
  margin: 48px 40px;
  overflow: hidden;
  .title {
    line-height: 60px;
    color: #fff;
    font-size: 36px;
    float: left;
  }
  .sz {
    height: 64px;
    float: right;
    overflow: hidden;   
    .text {
      float: left;
      color: #fff;
      font-size: 28px;
      line-height: 64px;
      margin-left: 20px;
    }
  } 
  .rb {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    margin-right: 8px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  .rb1 {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    .icon {
      width: 44.1px;
      height: 44.1px;
      margin: 10px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 28px;
      line-height: 64px;
    }
  }
}

.rb2 {
    height: 80px;
    width: 80px;
    overflow: hidden;
    float: right;
    .icon1 {
      width: 50px;
      height: 50px;
      margin: 8px;
      display: block;

    }
  }
.content {
  padding: 0px;
  margin-top: 40px;
}

.xg {
  width: 660px;
  overflow: hidden;
  margin: 0px auto;
  padding: 30px 0px 20px 0px;
}

.items {
  overflow: hidden;
  padding-bottom: 32px;
  min-height: 616px;
  .item {
    margin: 0px 26px;
    padding: 24px 14px 22px;
    overflow: hidden;
    display: flex;
    border-bottom: 2px solid #eee;
  }
  .box {
    flex: 1;
    overflow: hidden;
    flex:0 0 auto;
    &.zz {
      width: 80%;
    }
    &.yy {
      width: 20%;
    }
    &.yy2 {
      width: 10%;     
    }
    &.yy1 {
      width: 34%;
    }
    &.qq {
      width: 100%;
    }
    .name {
      line-height: 50px;
      overflow: hidden;
      font-size: 32px;
      .headline {
        font-size: 26px;       
        color: rgb(136, 134, 134);   
        &.h1 {
          margin-right: 30px;
          float: left;
        }
        &.h2 {
          float: right;         
        }
        &.cc {
          color: rgb(255, 255, 255);   
        }
        &.d1 {
          margin-right: 100px;
          margin-left: 30px; 
        }
        &.d2 {
          margin-right: 30px;
        }
        &.d3 {
          margin-left: 10px; 
        }
        &.d5 {
          margin-right: 15px; 
        }
        &.d6 {
          margin-right: 0px; 
        }
        &.xz {
          color: rgb(14, 177, 206);   
        }
        &.cur {
         cursor: pointer;
        }
        .datespace {
          width: 190px; 
          height: 50px; 
          /* 清除默认边框 */
          border: 0;
          /* 清除默认的箭头样式 */
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          background-color: transparent;
          cursor: pointer;  
          &.bt {
            width: 110px; 
          }          
        }
      }
      .dxicon {
        width: 35px;
        height: 35px;
        margin-right: 10px;
        vertical-align: middle;
        margin-top: -6px;
      }
      .roomcode {
        margin-right: 20px;
      }
      .region {
        font-size: 26px;
        color: rgb(184, 188, 190);
      }
      .cx {
        height: 76px;
        vertical-align: middle;
      }
      .icon {
        width: 48px;
        height: 48px;
        vertical-align: middle;
      }
      .input {
        flex: 1;
        margin-right: 15px;
        margin-top: 10px;
        vertical-align: middle;
        line-height: 76px;
        width: 260px;
        border: 0;
        color: #333;
        float: left;
        &.qy {
          font-size: 30px;
          color: rgb(184, 188, 190);
        }
      }
      ::-webkit-input-placeholder { /* WebKit browsers */
        color:rgb(184, 188, 190);
      }
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color:rgb(184, 188, 190);
      }
      ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color:rgb(184, 188, 190);
      }
      :-ms-input-placeholder { /* Internet Explorer 10+ */
        color:rgb(184, 188, 190);
      }
      .rb2 {
        height: 60px;
        width: 125px;
        float: left;
        overflow: hidden;
        background: rgb(130, 189, 217);
        margin-top: 16px;
        margin-left: 10px;
        border-radius: 15px;
        .icon {
          width: 30px;
          height: 30px;
          margin: 14px 5px 14px 13px;
          display: block;
          float: left;
        }        
        .text {
          float: left;
          color: #fff;
          font-size: 30px;
          line-height: 60px;
        }
      }
      .form_select {
        /* 清除默认边框 */
        border: 0;
        /* 清除默认的箭头样式 */
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        /* 右侧添加小箭头的背景图 */
        background: url('/img/xl.png') 80px center no-repeat;
        background-size: 26px;
        width: 110px;
        height: 76px;
        background-color: transparent;
        float: right;
        font-size: 30px;
        color: rgb(184, 188, 190);
        line-height: 76px;
        margin-top: 10px;
        vertical-align: middle;
      }
    }
    .name1 {
      line-height: 50px;
      overflow: hidden;
      font-size: 26px;
      .state {
        display: block;
        float: right;
        color: rgb(236, 120, 25);
        &.zt {
          color: rgb(4, 141, 61);
        }
      }
      .floor {
        display: block;
        float: right;
        color: rgb(184, 188, 190);
        margin-right: 20px;
      }
    }
    .unames {
      line-height: 40px;
      font-size: 26px;
      margin-top: 8px;
      color: #918e8e;
      &.dd {
        margin-top: 26px;
        float: right;
        color: rgb(243, 144, 78);
        font-size: 30px;
      }
      .pz {
        width: 46px;
        height: 46px;
      }
      .uu {
        margin-right: 20px;
      }
    }
    .del {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
    }
    .edit {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      margin-right: 20px;
    }
    .view {
      margin-top: 18px;
      width: 198px;
      height: 64px;
      border: 2px solid rgba(0, 29, 255, 1);
      border-radius: 44px;
      text-align: center;
      line-height: 64px;
      color: rgba(0, 29, 255, 1);
      font-size: 28px;
      float: right;
      margin-right: 30px;
    }
  }
}
.empty {
  text-align: center;
  margin-top: 212px;
  img {
    width: 150px;
  }
  .text {
    line-height: 40px;
    font-size: 28px;
    margin-top: 20px;
  }
}
</style>
